import React from 'react';
import './Home.css';

function Home() {
  return (
    <div className="home-container">
      <div className="text-box">
        <p>
          La innovación ha sido siempre el eje principal de nuestra actividad, 
          definiendo una línea de actuación muy marcada a la hora del desarrollo 
          de soluciones tecnológicas y estrategias empresariales.
        </p>
        <p>
          De esta manera CUALO incorpora en su portoflio diversas soluciones innovadoras 
          con aplicaciones en diferentes sectores de actividad: desde las soluciones orientadas 
          a la transformación digital de las relaciones con los clientes, hasta la renovación de 
          las capacidades orientadas a la gestión inteligente de las infraestructuras, o las soluciones 
          orientadas a la eficiencia real en la gestión energética.
        </p>
      </div>
    </div>
  );
}

export default Home;
