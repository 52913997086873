import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faLightbulb, faCity } from '@fortawesome/free-solid-svg-icons';
import './Services.css';
import trackAndMarkImg from './images/trackandmark.jpg';
import dataOverAudioImg from './images/dataoveraudio.jpg';
import IAImg from './images/ia.jpg';
import ISE from './images/ise.png';
import REGIN from './images/REGIN.jpg';

const Services = () => {
  const [showMore, setShowMore] = useState(false);
  const [titleVisible, setTitleVisible] = useState(false);
  const [widgetVisibility, setWidgetVisibility] = useState({
    marketing: false,
    efficiency: false,
    infrastructure: false,
  });

  const titleRef = useRef(null);
  const marketingRef = useRef(null);
  const efficiencyRef = useRef(null);
  const infrastructureRef = useRef(null);

  const toggleVisibility = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target === titleRef.current) {
            setTitleVisible(entry.isIntersecting);
          }
          if (entry.isIntersecting) {
            if (entry.target === marketingRef.current) {
              setWidgetVisibility((prevState) => ({ ...prevState, marketing: true }));
            }
            if (entry.target === efficiencyRef.current) {
              setWidgetVisibility((prevState) => ({ ...prevState, efficiency: true }));
            }
            if (entry.target === infrastructureRef.current) {
              setWidgetVisibility((prevState) => ({ ...prevState, infrastructure: true }));
            }
          }
        });
      },
      { threshold: 0.3 }
    );

    if (titleRef.current) observer.observe(titleRef.current);
    if (marketingRef.current) observer.observe(marketingRef.current);
    if (efficiencyRef.current) observer.observe(efficiencyRef.current);
    if (infrastructureRef.current) observer.observe(infrastructureRef.current);

    return () => {
      if (titleRef.current) observer.unobserve(titleRef.current);
      if (marketingRef.current) observer.unobserve(marketingRef.current);
      if (efficiencyRef.current) observer.unobserve(efficiencyRef.current);
      if (infrastructureRef.current) observer.unobserve(infrastructureRef.current);
    };
  }, []);

  return (
    <section id="services">
      <div className="title-section">
        <h1 className={`solutions-title ${titleVisible ? 'slide-in' : ''}`} ref={titleRef}>
          SOLUCIONES
        </h1>
      </div>

      {/* Main Content Section */}
      <div className="services-content">
        {/* Marketing Digital with three sub-widgets */}
        <div className={`widget ${widgetVisibility.marketing ? 'fade-in' : ''}`} ref={marketingRef}>
          <FontAwesomeIcon icon={faBullhorn} className="widget-icon" />
          <h2>Marketing Digital</h2>
          <div className="marketing-widgets">
            <div className={`marketing-widget ${showMore ? 'show-info' : ''}`}>
              <img src={trackAndMarkImg} alt="track&mark" className="marketing-img" />
              {showMore && (
                <>
                  <h3>Track&Mark</h3>
                  <p>
                    Basada en la integración de diferentes tecnologías innovadoras,
                    orientada a la detección y conocimiento del comportamiento de los
                    clientes en cualquier espacio (indoor & outdoor) y a la interacción
                    digital en tiempo real para un nuevo modelo de relación en la
                    experiencia de los clientes.
                  </p>
                </>
              )}
            </div>

            <div className={`marketing-widget ${showMore ? 'show-info' : ''}`}>
              <a href="https://natasquad.com/" target="_blank" rel="noopener noreferrer">
                <img src={dataOverAudioImg} alt="Data Over Audio" className="marketing-img" />
              </a>
              {showMore && (
                <>
                  <h3>Data Over Audio</h3>
                  <p>
                    Es una solución innovadora de transmisión de datos mediante Smart Tones,
                    ondas de sonido de alta frecuencia. Estos tonos, junto con un software adaptable,
                    envían datos a dispositivos inteligentes usando la infraestructura de audio existente.
                  </p>
                  <p>
                    Su aplicación DWOX crea experiencias digitales novedosas, mejorando la
                    participación en eventos como empresariales, musicales o deportivos.
                  </p>
                </>
              )}
            </div>

            <div className={`marketing-widget ${showMore ? 'show-info' : ''}`}>
              <a href="https://natasquad.com/" target="_blank" rel="noopener noreferrer">
                <img src={IAImg} alt="Inteligencia Artificial" className="marketing-img" />
              </a>
              {showMore && (
                <>
                  <h3>Inteligencia Artificial</h3>
                  <p>
                    CUALO ofrece un amplio abanico de soluciones basadas en Inteligencia Artificial
                    generativa: tanto en la generación de contenidos de texto, como de audiovisuales,
                    que aportan gran valor en la creación de contenido de calidad en múltiples aplicaciones:
                    e-commerce, publicidad interactiva y/o personalizada, innovación en la experiencia de cliente, etc.
                  </p>
                </>
              )}
            </div>
          </div>
          <button className="info-button" onClick={toggleVisibility}>
            {showMore ? 'Menos información' : 'Más información'}
          </button>
        </div>

        {/* Eficiencia Energética widget with ISE image */}
        <div className={`widget ${widgetVisibility.efficiency ? 'fade-in' : ''}`} ref={efficiencyRef}>
          <FontAwesomeIcon icon={faLightbulb} className="widget-icon" />
          <h2>Eficiencia Energética</h2>
          <div className="efficiency-content">
            <img src={ISE} alt="ISE" className="efficiency-img" />
            <div className="more-info">
              <p>
                CUALO ofrece ISE, una potente Solución para la Gestión de la Eficiencia Energética (EMS) a la medida
                del cliente, diseñada para integrarse en cualquier edificio o infraestructura en cualquier parte del mundo.
              </p>
              <p>
                Almacena los datos de consumo energético cuarto-horarios de miles de dispositivos de medida energética, así
                como otras muchas variables independientes (temperatura exterior, ocupación, etc.).
              </p>
              <p>
                Proporciona un potente motor analítico y de reporting para realizar la función de gestión energética.
              </p>
            </div>
          </div>
        </div>

        {/* Infraestructuras Inteligentes widget with REGIN image */}
        <div className={`widget ${widgetVisibility.infrastructure ? 'fade-in' : ''}`} ref={infrastructureRef}>
          <FontAwesomeIcon icon={faCity} className="widget-icon" />
          <h2>Infraestructuras Inteligentes</h2>
          <div className="infrastructure-content">
            <div className="more-info">
              <p>
                CUALO aporta una visión innovadora y vanguardista en la Gestión de Infraestructuras Inteligentes (BMS) basada
                en la toma del control por parte del propietario de la misma, evitando la excesiva dependencia de los
                fabricantes tradicionales y aportando una modernización inédita en este importante aspecto de la gestión de
                los activos empresariales.
              </p>
              <p>
                Cualo colabora con Regin como solución comprometida con el nuevo paradigma de la modernización en la gestión de las infraestructuras.
              </p>
            </div>
            <a href="https://www.regincontrols.com/es/es/" target="_blank" rel="noopener noreferrer">
              <img src={REGIN} alt="REGIN" className="infrastructure-img" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
