import React from 'react';
import './Contact.css';
import EMAIL from './images/cualomail.png';

const Contact = () => {
  return (
    <section id="contact" className="contact-section">
      <div className="contact-container">
        {/* Left side: Contact Information */}
        <div className="contact-info">
          <h2 className="contact-title">Nuestra Ubicación:</h2>
          <p className="contact-details">
            <strong>Dirección:</strong> <br />
            Av. de la Victoria, 25 - Oficina 11 <br />
            El Plantío - 28023 <br/>
            Madrid, España
          </p>
          <p className="contact-details">
            <strong>Correo:</strong> <br />
            <img src={EMAIL} alt="EMAIL" />
          </p>
        </div>

        {/* Right side: Map */}
        <div className="contact-map">
          <iframe
            title="office-location-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3035.3874020730577!2d-3.8156432234912314!3d40.466693752596086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd41869715d55d89%3A0x720c33dd97e0bc09!2sAv.%20de%20la%20Victoria%2C%2025%2C%20Moncloa%20-%20Aravaca%2C%2028023%20Madrid!5e0!3m2!1ses!2ses!4v1726601597507!5m2!1ses!2ses"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Contact;