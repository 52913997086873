import React, { useEffect, useRef, useState } from 'react';
import './About.css';
import GONZALO from './images/GONZALO.jpeg';
import PABLOF from './images/PABLOF.jpg';
import FERNANDO from './images/FERNANDO.jpg';
import PABLO from './images/PABLO.jpg';
import JAVIER from './images/JAVIER.jpg';
import JLCASAL from './images/JLCASAL.png';

const About = () => {
  const [isTitleVisible, setIsTitleVisible] = useState(false);
  const titleRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Only handle the current titleRef
          if (entry.target === titleRef.current) {
            // Set visibility state based on whether the element is intersecting
            setIsTitleVisible(entry.isIntersecting);
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the element is visible
    );

    // Ensure the reference is set before observing
    const currentRef = titleRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    // Cleanup observer on component unmount or when the reference changes
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
}, []);


  return (
    <section id="about" className="about-section">
      <h1
        ref={titleRef}
        className={`about-title ${isTitleVisible ? 'slide-in' : ''}`}
      >
        CONOCE AL EQUIPO
      </h1>

      {/* Grid of widgets */}
      <div className="about-grid">
        <div className="about-widget">
          <div className="widget-content">
            <div className="widget-img">
              <img src={GONZALO} alt="GonzaloRuiz" />
            </div>
            <div className="widget-info">
              <h2 className="team-member-name">Gonzalo Ruiz de Julián</h2>
              <h3 className="team-member-position">Director General</h3>
              <p className="team-member-description">
              Director y emprendedor movido por la excelencia, con una visión estratégica amplia, 
              capacidad de innovación y un enfoque estructurado hacia procesos de desarrollo 
              corporativo.
              </p>
              <p className="team-member-description">
              Cuenta con una experiencia sólida en gestión, desarrollo empresarial, 
              relaciones e inversiones comerciales, así como habilidades para consolidar 
              alianzas estratégicas con corporativas y profesionales individuales.
              </p>
            </div>
          </div>
        </div>

        <div className="about-widget">
          <div className="widget-content">
            <div className="widget-img">
              <img src={FERNANDO} alt="FernandoCuesta" />
            </div>
            <div className="widget-info">
              <h2 className="team-member-name">Fernando Cuesta</h2>
              <h3 className="team-member-position">Senior Partner BMS</h3>
              <p className="team-member-description">
              Con más de 40 años de experiencia en el Sector. Fernando es, sin duda,  
              uno de los mayores expertos en el área BMS en el mercado español. Cuenta 
              con gran número de referencias y proyectos a sus espaldas aportando siempre 
              una visión innovadora desde la perspectiva de la “democratización” de los 
              sistemas de BMS para hacerlos sistemas más eficientes al servicio del cliente 
              y sus necesidades de gestión de la infraestructura.
              </p>
            </div>
          </div>
        </div>

        <div className="about-widget">
          <div className="widget-content">
            <div className="widget-img">
              <img src={JAVIER} alt="JavierSolueta" />
            </div>
            <div className="widget-info">
              <h2 className="team-member-name">Javier Solueta</h2>
              <h3 className="team-member-position">CTO</h3>
              <p className="team-member-description">
              Como CTO, aporta una perspectiva de aplicación de la tecnología directamente al negocio,
              para centrarnos en facilitar y agilizar la identificación de nuevas oportunidades 
              tecnológicas y la puesta en marcha de pruebas de concepto e implantación de los proyectos 
              en cliente final con el menor estrés tecnológico posible.
              </p>
              <p className="team-member-description">
              Con más de veinticinco años de experiencia profesional en tecnología e innovación, 
               desde sectores muy diversos.
              </p>
            </div>
          </div>
        </div>

        <div className="about-widget">
          <div className="widget-content">
            <div className="widget-img">
              <img src={PABLO} alt="PabloLatasa" />
            </div>
            <div className="widget-info">
              <h2 className="team-member-name">Pablo Latasa</h2>
              <h3 className="team-member-position">Senior Partner</h3>
              <p className="team-member-description">
              Cuenta con más de 35 años de experiencia introduciendo importantes proveedores 
              de software y analistas de IT en los mercados de Europa, Latinoamérica, Estados 
              Unidos y Oriente Medio con el fin de aumentar el valor de mercado con cada oportunidad.
              </p>
              <p className="team-member-description">
                La interacción personal con el equipo, socios y clientes, la planificación y
                 organización del trabajo son algunas de sus principales fortalezas, junto con 
                 su pasión por todo lo que hace.
              </p>
            </div>
          </div>
        </div>

        <div className="about-widget">
          <div className="widget-content">
            <div className="widget-img">
              <img src={PABLOF} alt="PabloFernández" />
            </div>
            <div className="widget-info">
              <h2 className="team-member-name">Pablo Fernández de la Torre</h2>
              <h3 className="team-member-position">Senior Partner</h3>
              <p className="team-member-description">
              Cuenta con gran experiencia en sistemas de gestión energética y control de instalaciones.
               Emprendedor en serie de varias startups en el sector de las Energías Limpias, entre 
               ellas el Grupo Alium y N2ESE, que cuenta un software propio de gestión energética 
               implantado en clientes de referencia.
              </p>
              <p className="team-member-description">
              Anteriormente, ha sido socio fundador y CEO de N2S, empresa de referencia en software
              de gestión energética; y fundador de Kwiil.com, comercializadora on-line de referencia.
               También fue Director de Desarrollo de Negocio para TNT Logistics España y Portugal y 
               Gerente de consultoría de gestión en McKinsey & Company.
              </p>
            </div>
          </div>
        </div>

        <div className="about-widget">
          <div className="widget-content">
            <div className="widget-img">
              <img src={JLCASAL} alt="JoseLuisCasal" />
            </div>
            <div className="widget-info">
              <h2 className="team-member-name">José Luis Casal Castro</h2>
              <h3 className="team-member-position">Senior Partner</h3>
              <p className="team-member-description">
              Financiero con Máster en Marketing y Dirección Comercial, Máster en Inteligencia Artificial y Máster en Innovación y Blockchain. 
              Es socio fundador de varios proyectos y miembro del Consejo y Advisory Board de empresas y startups tecnológicas, y de una gestora de capital riesgo.
              </p>
              <p className="team-member-description">
              Miembro del consejo de Harvard Business Review. Su newsletter, "Better business for a better world", cuenta con más de 50.000 suscriptores. 
              Ha sido nombrado 'TOP Voices' por LinkedIn, y, también, se le considera una de las 50 personas
              que más han influido en los negocios digitales en España.
              </p>
            </div>
          </div>
        </div>


      </div>
    </section>
  );
};

export default About;