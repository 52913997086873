import React, { useState, useEffect, useRef } from 'react';
import Home from './Home';
import Services from './Services';
import About from './About';
import Partners from './Partners';
import Contact from './Contact';
import './App.css';
import logo from './images/CUALOlogo.png';

function App() {
  const [activeSection, setActiveSection] = useState('home');

  // Refs for each section
  const homeRef = useRef(null);
  const servicesRef = useRef(null);
  const aboutRef = useRef(null);
  const partnersRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    const sections = [
      { id: 'home', ref: homeRef },
      { id: 'services', ref: servicesRef },
      { id: 'about', ref: aboutRef },
      { id: 'partners', ref: partnersRef },
      { id: 'contact', ref: contactRef }
    ];

    const observerOptions = {
      threshold: 0.1,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    sections.forEach((section) => {
      if (section.ref.current) observer.observe(section.ref.current);
    });
    return () => {
      sections.forEach((section) => {
        if (section.ref.current) observer.unobserve(section.ref.current);
      });
    };
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <nav className="nav-links">
          <a href="#home" className={activeSection === 'home' ? 'active' : ''}>
            Inicio
          </a>
          <a href="#services" className={activeSection === 'services' ? 'active' : ''}>
            Soluciones
          </a>
          <a href="#about" className={activeSection === 'about' ? 'active' : ''}>
            Quiénes Somos
          </a>
          <a href="#contact" className={activeSection === 'contact' ? 'active' : ''}>
            Contacto
          </a>
        </nav>
      </header>

      <div id="home" ref={homeRef}>
        <Home />
      </div>
      <div id="services" ref={servicesRef}>
        <Services />
      </div>
      <div id="about" ref={aboutRef}>
        <About />
      </div>
      <div id="partners" ref={partnersRef}>
        <Partners />
      </div>
      <div id="contact" ref={contactRef}>
        <Contact />
      </div>
    </div>
  );
}

export default App;
