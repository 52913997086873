import React from 'react'; 
import './Partners.css';
import REGIN from './images/REGINlogo.jpg';
import COPSONIC from './images/COPSONIClogo.jpg';
import BEEPSTREAM from './images/BEEPSTREAMlogo.jpg';
import NATEEVO from './images/NATEEVOlogo.png';

const Partners = () => {
  return (
    <section id="partners" className="partner-section">
      <div className="partner-carousel">
        <div className="carousel-track">
          {/* Partner Logos */}
          <img src={REGIN} alt="REGIN" className="partner-logo" />
          <img src={COPSONIC} alt="COPSONIC" className="partner-logo" />
          <img src={BEEPSTREAM} alt="BEEPSTREAM" className="partner-logo" />
          <img src={NATEEVO} alt="NATEEVO" className="partner-logo" />
          
          {/* Duplicated Partner Logos for Continuous Scroll */}
          <img src={REGIN} alt="REGIN" className="partner-logo" />
          <img src={COPSONIC} alt="COPSONIC" className="partner-logo" />
          <img src={BEEPSTREAM} alt="BEEPSTREAM" className="partner-logo" />
          <img src={NATEEVO} alt="NATEEVO" className="partner-logo" />
        </div>
      </div>
    </section>
  );
};

export default Partners;


